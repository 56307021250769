html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .app-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  main {
    flex: 1;
  }
  