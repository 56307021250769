header {
    text-align: center;
}

.activiteiten {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    height: 600px;
    margin: 20px;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; 
    cursor: pointer;
    user-select: none;
    
}


