.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0028ca;
  }
  
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #0028ca;
    transform-origin: bottom right;
    transition: transform 0.5s ease-in-out;
  }
  
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }