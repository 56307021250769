.card {
    width: 300px;
    margin: 20px;
    flex: 0 0 auto;
  }
  
  .card .card-body {
    width: 100%; 
    word-wrap: break-word; 
  }
  
  .card:hover {
    transform: scale(1.2);
  }
